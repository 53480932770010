import React, { useState } from 'react'; 
import { Link } from 'react-scroll';
import './Mhamb.css';  

function Mhamb() { 
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
 const myImage = 'https://duraxl.turvidainc.com/Content/duraxl.png';     
 
  return (
    <div className="iVnavbar-da">
      {/*** <div className="iVcc-container"> 
          <a className="navbar-brand" href="/">
            <img className="cc-icons-in" src={myImage} alt="OpenOfix" />
          </a> 
  </div> ***/}


<div className="iVcc-container">
      <div className="Nav-Left">
        <Link to="home"  smooth={true} duration={500} className="navbar-item"><img className="cc-icons-in" src={myImage} alt="DuraXL" /></Link>
      </div>
      <div className="Nav-Right">
        <div className={`navbar-menu ${isOpen ? 'is-active' : ''}`}>
          <div className="navbar-end">            
              <div className="MItems-Navbar"><Link to="why" smooth={true} duration={500} className="navbar-item" >Por qu&eacute;</Link></div>
              <div className="MItems-Navbar"><Link to="Benefits" smooth={true} duration={500} className="navbar-item" >Beneficios</Link></div>
              <div className="MItems-Navbar"><Link to="Solved" smooth={true} duration={500} className="navbar-item" >Problema resuelto</Link></div>
              <div className="MItems-Navbar"><Link to="Works" smooth={true} duration={500} className="navbar-item" >Como funciona</Link></div>
              <div className="MItems-Navbar"><Link to="Science" smooth={true} duration={500} className="navbar-item" >Ciencia</Link></div>
              <div className="MItems-Navbar"><Link to="Howto" smooth={true} duration={500} className="navbar-item" >C&oacute;mo utilizar</Link></div>
              <div className="MItems-Navbar"><Link to="Compare" smooth={true} duration={500} className="navbar-item" >Comparar</Link></div>
              <div className="MItems-Navbar"><Link to="Testimonials" smooth={true} duration={500} className="navbar-item" >Testimonios</Link></div>
              <div className="MItems-Navbar"><Link to="Faq" smooth={true} duration={500} className="navbar-item" >Preguntas m&aacute;s frecuentes</Link> </div>
          </div>
        </div>
        <div className={`navbar-burger ${isOpen ? 'is-active' : ''}`} onClick={toggleNavbar}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-list" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
          </svg>
        </div>
      </div>
</div> 
</div>
  );
}

export default Mhamb; 
                      
