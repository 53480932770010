import React, { Suspense } from 'react'; 
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history'; 
import './index.css';
import Loading from './components/Loading'; 
import Head from './components/Mhamb';  
import reportWebVitals from './reportWebVitals'; 

const App = React.lazy(() => import('./App'));
const history = createBrowserHistory();
const rootElement = document.getElementById('root');
 
createRoot(rootElement).render(                              
    <Suspense fallback={<Loading />}>   
      <Router history={history}> 
        <Head />                    
        <App />                                                                              
      </Router> 
    </Suspense>,             
  rootElement
);

reportWebVitals(); 
  