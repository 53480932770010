import React from 'react';
import './Loading.css'; 

function Loading() {
  return (
    <div>
      <div className='loadding-ppl'>
        <img src="https://tinax.turvidainc.com/images/vigor/vigor.png" alt="Loading" />
      </div>
    </div>
  );
}

export default Loading;